'use client'

import { useSelector } from 'react-redux'
import { Button, Spacer } from '@vinted/web-ui'

import SeparatedList from 'components/SeparatedList'
import { ROOT_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import useBreakpoint from 'hooks/useBreakpoint'
import useRefUrl from 'hooks/useRefUrl'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { clickEvent } from '_libs/common/event-tracker/events'
import * as sessionSelectors from 'state/session/selectors'
import { navigateToPage } from '_libs/utils/window'
import { acceptTermsAndConditions, skipTermsAndConditions } from 'data/api'

import { AdditionalActionDto } from '../types'

type Props = {
  canSkip: boolean
  additionalActions: Array<AdditionalActionDto>
}

const TermsActions = ({ canSkip, additionalActions }: Props) => {
  const breakpoints = useBreakpoint()
  const refUrl = useRefUrl(ROOT_URL)
  const { track } = useTracking()
  const translate = useTranslate('terms_and_conditions.actions')
  const isRestrictedByTerms = useSelector(sessionSelectors.getIsUserRestrictedByTerms)
  const userId = useSelector(sessionSelectors.getUserId)

  const buttonSize = breakpoints.tabletsUp ? undefined : Button.Size.Medium
  const screen = isRestrictedByTerms ? Screen.TermsAndConditionsForced : Screen.TermsAndConditions

  const trackClick = (target: ClickableElement) => {
    track(clickEvent({ target, screen, env: 'js' }))
  }

  const handleSkip = async () => {
    trackClick(ClickableElement.SkipTerms)
    const response = await skipTermsAndConditions()
    if ('errors' in response) return

    navigateToPage(refUrl)
  }

  const handleAcceptOnTerms = async () => {
    trackClick(ClickableElement.AcceptTerms)
    if (!userId) return

    const response = await acceptTermsAndConditions(userId)
    if ('errors' in response) return

    navigateToPage(refUrl)
  }

  const renderSkipButton = () => {
    if (isRestrictedByTerms || !canSkip) return null

    return (
      <Button
        text={translate('skip')}
        styling={Button.Styling.Flat}
        size={buttonSize}
        onClick={handleSkip}
        testId="terms-button-skip"
      />
    )
  }

  const renderAdditionalAction = ({ title, url }: AdditionalActionDto, index: number) => (
    <Button
      key={title}
      text={title}
      styling={Button.Styling.Flat}
      url={url}
      size={buttonSize}
      onClick={() => trackClick(ClickableElement.RedirectTerms)}
      testId={`terms-button-additional-${index}`}
    />
  )

  return (
    <SeparatedList
      separatorAtBreakpoint={{
        tablets: <Spacer size={Spacer.Size.Regular} />,
        desktops: <Spacer size={Spacer.Size.Large} />,
      }}
    >
      <Button
        text={translate('agree')}
        styling={Button.Styling.Filled}
        size={buttonSize}
        onClick={handleAcceptOnTerms}
        testId="terms-button-agree"
      />
      {renderSkipButton()}
      {additionalActions.map(renderAdditionalAction)}
    </SeparatedList>
  )
}

export default TermsActions
