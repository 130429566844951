'use client'

import useTranslate from 'hooks/useTranslate'
import TermsAndConditions from 'pages/TermsAndConditions'

import PageHead from '../../components/Head/PageHead'
import fullPageLayout from '../../layouts/FullPage'
import { getLayoutServerSideProps } from '../../layouts/FullPage/server-props'
import { emptyPropsCallback } from '../../libs/server-utils/ssr'

const TermsAndConditionsPage = () => {
  const translate = useTranslate('terms_and_conditions')

  return (
    <>
      <PageHead title={translate('page_title')} isIndexed />
      <TermsAndConditions />
    </>
  )
}

export default fullPageLayout(TermsAndConditionsPage)

export const getServerSideProps = getLayoutServerSideProps(emptyPropsCallback)
